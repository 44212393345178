import '../Styles/App.scss';
import {Header,Nav,Main,Button,SNS,Article,Footer} from './Components';




function App() {
  window.addEventListener('scroll', () => {
    document.body.style.setProperty('--scroll',window.pageYOffset / (document.body.offsetHeight - window.innerHeight));
  }, false);
  
  return (
    <div className="App">
     <Header></Header>
     <Nav></Nav>
     <Main></Main>
     <Button></Button>
     <SNS></SNS>
      <Article></Article>
     <Footer></Footer>
    </div>
  );
}

export default App;