import {useState,useEffect} from 'react';
import { findAllInRenderedTree } from 'react-dom/test-utils';
import {animateScroll} from 'react-scroll';


export function Header(props){
    return <header id="Header"><h1 hidden>온리팬스 에이전시</h1><h1>PERFORMANCE IN CLASS</h1></header>
  }
  
export function Nav(props){
    
    let [open, setOpen] = useState(false); 
  
    function Scroll(e,number){
      e.preventDefault();
      if(window.innerWidth>768){ // desktop
      animateScroll.scrollTo(number,{smooth:true, spy:true,activeClass:"active"})
      }
      else if(number<1000){ // mobile + about
        animateScroll.scrollTo(number,{smooth:true, spy:true,activeClass:"active"})
        setOpen(!open);
  
      }
      else { // mobile
        animateScroll.scrollTo(number+100,{smooth:true, spy:true,activeClass:"active"})
        setOpen(!open);
      }
    }
  
    return <div id="Menu">
  
        <div className="menu-btn" onClick={()=>setOpen(!open)}>
            <div className="menu-burger"></div>
        </div>
        
        <nav className={`Nav ${open?'active':'inactive'}`}>
            <ul>
              <li className="about">
                <a href="/about" onClick={(e)=>Scroll(e,730)}>ABOUT</a></li>
              <li className="services">
                <a href="/services" onClick={(e)=>Scroll(e,1400)}>SERVICES</a>
              </li>
              <li className='home'>
                <a href="/" onClick={(e)=>Scroll(e,0)}><span aria-hidden="true">HOME</span></a>
              </li>
              <li className="overviews">
                <a href="/overview"onClick={(e)=>{e.preventDefault();alert('Coming Soon')}}>OVERVIEW</a>
              </li>
              <li className="contact">
                <a href="/contact"onClick={(e)=>Scroll(e,9999)}>CONTACT</a>
              </li>  
            </ul>
        </nav>
  
      </div>
  }
  
  export function Main(props){
    return <main id="Main">
      
      <h1>
      WHAT IS ASCENDING LIGHTS AGENCY?<p hidden>온리팬스 에이전시</p>
      </h1>
  
      <p>
        <b>ASCENDING LIGHTS AGENCY <strong>(ALA)</strong></b> IS A COMPANY THAT CREATES CONTENTS,TRAINING AND MANAGING THE CLIENTS.<br/>
        WHATEVER DIRECTION YOU'RE HEADING, IT'S TOTALLY UP TO YOU TO DECIDE.<br/>
        ALA WILL ALWAYS BE THERE FOR YOU WITHOUT COMPELLING ANYTHING<br/>
  
        <br/>
        WITH ALA, YOU CAN BE ANY FORM OF PROFESSIONAL ARTIST, INFLUENCER AND A DJ WHILE MAINTAINING YOUR DIGNITY AND CLASS<br/>
          
        <br/>
        ALA HAS PROFESSIONAL PRODUCERS, DIRECTORS, MANAGERS, PHOTOGRAPHERS, EDITORS, YOU HAVE THEM<br/>
        <br/>
        WE WILL PRODUCE THE THINGS THAT REQUIRES A LONG TIME TO PERFECTING WITH THOSE PROFESSIONALS <br/>
        AND MENTORS SO YOU COULD BE FOCUSING MASTERING YOUR SKILLSETS AND MAKE YOU ONE OF A KIND<br/>
        <br/>
        YOU CAN BE FLYING AROUND THE WORLD AND ENJOYING WHILE WORKING ON YOUR CAREER, OR VISITING OTHER COUNTRIES TO PLAY MUSIC AT A NIGHTCLUB
        <br/><br/>
        ALA DIRECTORS WILL LEVEL UP EVERY LITTLE DETAILS OF YOUR CAREER SO THAT YOU COULD FLY UP AND BE THE BRIGHTEST STAR IN THE SKY
      </p>
  
      </main>
  }

  export function Button(){

    return <div id="Button">
      <div className="MeetTheCarde">
        <a href="https://www.instagram.com/alagencyofficial" target="_blank" className="button_link">
          MEET THE CADRE
        </a>
      </div>
    </div>

  }

  export function SNS(){

    return <div id="SNS">
  
      <a href="https://www.instagram.com/alagencyofficial/?next=%2F"target="_blank">
        <i className="fab fa-instagram fa-2x"></i> 
      </a>
  
      <a href="mailto:alagencyservice@gmail.com" 
        onClick={(e)=>{
        navigator.clipboard.writeText("alagencyservice@gmail.com")
        .then(
          ()=>{
            alert('Email Address is Successfully Copied');
          },
          ()=>{
            alert('copy failed'); 
          }
          )}}>
          <svg xmlns="http://www.w3.org/2000/svg" width="32px" height="32px" fill="white" viewBox="0 0 512 512"><path d="M64 112c-8.8 0-16 7.2-16 16v22.1L220.5 291.7c20.7 17 50.4 17 71.1 0L464 150.1V128c0-8.8-7.2-16-16-16H64zM48 212.2V384c0 8.8 7.2 16 16 16H448c8.8 0 16-7.2 16-16V212.2L322 328.8c-38.4 31.5-93.7 31.5-132 0L48 212.2zM0 128C0 92.7 28.7 64 64 64H448c35.3 0 64 28.7 64 64V384c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V128z"/></svg>
      </a>
  
    </div>

  }


  export function Article(){

    let [open, setOpen] = useState("profession"); 
    function clickMode (e,mode){
      e.preventDefault();
      setOpen(mode);
    }
  
    return <article id="Article">
        <ul id="Article_list">

          <li className={`lists ${open==="profession"?'active':'inactive'}`} >

            <a href=""   onClick={(e)=>clickMode(e,"profession")}>
              <img className="logo" src={require("../Img/logo/BL_LOGO_1.png")} alt="PROFESSION"></img>
              <h2 className="title">PROFESSION</h2>
            </a>
  
            <div className="description">
                <p>
                    ALA WILL PROVIDE ANY KIND OF EDUCATION OR SERVICES WITH A PROFESSIONALISM.<br/>
                    WITH ALA, YOU WILL LEARN AND INCREASE THE LEVELS OF YOUR TALENTS, AND YOUR FINANCE TO UTMOST LEVEL.
                </p>
            </div>

          </li>
  
  
          <li className={`lists ${open==="performance"?'active':'inactive'}`}>

            <a href=""onClick={(e)=>clickMode(e,"performance")}>
              <img className="logo"src={require("../Img/logo/BL_LOGO_2.png")} alt="PERFORMANCE"></img>
              <h2 className="title">PERFORMANCE</h2>
            </a>
  
            <div className="description">
                <p>
                    ALA WILL PROVIDE YOU THE SOURCES TO HELP YOU SETTING UP YOUR GOALS. 
                     WE WILL CUSTOMIZE ALL THE SPECIFIC STRATEGIES FOR YOU, SO YOU COULD PERFOM AS THE BEST VERSION OF YOURSELF.
                </p>
            </div>

          </li>
  
  
          <li className={`lists ${open==="profit"?'active':'inactive'}`}>

            <a href=""onClick={(e)=>clickMode(e,"profit")}>
              <img className="logo"src={require("../Img/logo/BL_LOGO_3.png")} alt="PROFIT"></img>
              <h2 className="title">PROFIT</h2>
            </a>
  
            <div className="description">
              <p>
                ALA WILL HELP YOU MAKE YOUR DREAMS COME TRUE. BY WORKING WITH OUR PROFESSIONAL DEPARTMENTS AND MANAGERS, YOU'LL BE ABLE TO MAKE YOUR DREAMS COME TRUE, NOT JUST CHASING THEM.
              </p>
            </div>

          </li>
  
  
          <li className={`lists ${open==="goals"?'active':'inactive'}`}>

            <a href=""onClick={(e)=>clickMode(e,"goals")}>
              <img className="logo"src={require("../Img/logo/BL_LOGO_4.png")} alt="GOALS"></img>
              <h2 className="title">GOALS</h2>
            </a>
  
            <div className="description">
              <p>
                ALA WILL OFFER YOU VARIOUS OPTIONS FOR THE FUTURE EVEN AFTER YOU'VE COMPLETED YOUR GOALS. WE WILL HELP YOU SET UP THE LONG-TERM LIFE GOALS FOR YOUR LIFE.
              </p>
            </div>

          </li>

        </ul>
    </article>
  }

  export function Overview(){
    return null;
  }


  export function Footer(){
    return <footer id="Footer">
      <h2>ALA Footer</h2>

      <section className='Footer_Contact'>
        <ul className='contactlists'>
  
          <li className="ContactUs">
            <h3>Contact Us</h3><br/>
            <a href="" onClick={(e)=>{
                e.preventDefault();
                navigator.clipboard.writeText("alagencyservice@gmail.com")
                .then(
                  ()=>{
                    alert('Email Address is Successfully Copied');
                  },
                  ()=>{
                    alert('copy failed'); 
                  }
                  )
                }}>&#9993; alagencyservice@gmail.com</a>
          </li>

          <li className='logo'>
          </li>
  
          <li className="FollowMe">
            <h3>Follow Me</h3><br/>
            <a href="https://www.instagram.com/alagencyofficial" target="_blank">	&#9993; https://www.instagram.com/alagencyofficial</a>
          </li>
  
        </ul>
      </section>
  
      <section className='Footer_Copyright'>
        <div className='Footer_Copyright_Copyright'>
            Copyright &copy; 2023 ALA Inc. All Rights Resereved.
            Live Your Dream. Go Forward. 
        </div>
      </section>
  
    </footer>
  }
  